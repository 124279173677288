import React, { useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { baseViewDispatch, createAlert } from 'lib/util'
import {
  Container,
  Segment,
  Header,
  Form,
  Input,
  Button,
  List,
  Dropdown,
  Loader,
} from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'

const FaissManager = ({ login, success, error }) => {
  const [text, setText] = useState('')
  const [entryId, setEntryId] = useState('')
  const [query, setQuery] = useState('')
  const [k, setK] = useState(3)
  const [searchResults, setSearchResults] = useState([])
  const [table, setTable] = useState('vector_schema')
  const [askPrompt, setAskPrompt] = useState('')
  const [askResponse, setAskResponse] = useState('')
  const [loading, setLoading] = useState(false)

  const token = login.data.user.token

  const tableOptions = [
    { key: 'vector_schema', value: 'vector_schema', text: 'Schema Vettoriale' },
    { key: 'vector_knowledgebase', value: 'vector_knowledgebase', text: 'Base di Conoscenza Vettoriale' },
  ]

  const addTextEntry = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_HERCULES}faiss/add`,
        { text, table },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      success(
        createAlert(
          'Voce aggiunta.',
          'La voce è stata aggiunta con successo!',
          'Visualizza',
          () => {
            // La navigazione o azioni aggiuntive possono andare qui.
          }
        )
      )
    } catch (err) {
      error(
        createAlert(
          'Errore nell\'aggiunta della voce.',
          'C\'è stato un errore nell\'aggiunta della voce.',
          'Riprova',
          () => {
            // La logica di riprova o azioni aggiuntive possono andare qui.
          }
        )
      )
    }
  }

  const deleteEntryById = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_HERCULES}faiss/delete/${entryId}?table=${table}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      success(
        createAlert(
          'Voce eliminata.',
          'La voce è stata eliminata con successo!',
          'Visualizza',
          () => {
            // La navigazione o azioni aggiuntive possono andare qui.
          }
        )
      )
    } catch (err) {
      error(
        createAlert(
          'Errore nell\'eliminazione della voce.',
          'C\'è stato un errore nell\'eliminazione della voce.',
          'Riprova',
          () => {
            // La logica di riprova o azioni aggiuntive possono andare qui.
          }
        )
      )
    }
  }

  const searchEntries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HERCULES}faiss/search?q=${query}&k=${k}&table=${table}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setSearchResults(response.data.results)
      success(
        createAlert(
          'Ricerca Completata',
          'I risultati della tua ricerca sono stati aggiornati!',
          'Visualizza',
          () => {}
        )
      )
    } catch (err) {
      error(
        createAlert(
          'Errore nella Ricerca',
          'C\'è stato un errore nell\'esecuzione della ricerca.',
          'Riprova',
          () => {}
        )
      )
    }
  }

  const askQuestion = async () => {
    setLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HERCULES}faiss/ask`,
        {
          prompt: askPrompt,
          model: 'gpt-4o',
          k: 10,
          temperature: 0.7,
          max_tokens: 4000,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setAskResponse(response.data.choices[0].message.content)
      success(
        createAlert(
          'Domanda Risposta',
          'La risposta alla tua domanda è stata ricevuta!',
          'Visualizza',
          () => {}
        )
      )
    } catch (err) {
      error(
        createAlert(
          'Errore nella Domanda',
          'C\'è stato un errore nel porre la domanda.',
          'Riprova',
          () => {}
        )
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container style={{ padding: '2em 0' }}>
      <Header as="h2" textAlign="center">
        Gestore VECTOR DB e AI
      </Header>

      {/* Sezione Fai una Domanda */}
      <Segment padded style={{ marginBottom: '20em' }}>
        <Header as="h3">Fai una Domanda</Header>
        <Form>
          <Form.Field>
            <Input
              fluid
              size="large"
              placeholder="Inserisci la tua domanda"
              value={askPrompt}
              onChange={(e) => setAskPrompt(e.target.value)}
            />
          </Form.Field>
          <Button secondary fluid size="large" onClick={askQuestion} disabled={loading}>
            Chiedi
          </Button>
        </Form>
        {loading && <Loader active inline="centered" />}
        {askResponse && (
          <Segment>
            <Header as="h4">Risposta</Header>
            <ReactMarkdown>{askResponse}</ReactMarkdown>
          </Segment>
        )}
      </Segment>

      {/* Selezione Tabella */}
      <Segment padded>
        <Header as="h3">Seleziona Tabella</Header>
        <Dropdown
          placeholder="Seleziona Tabella"
          fluid
          selection
          options={tableOptions}
          value={table}
          onChange={(e, { value }) => setTable(value)}
        />
      </Segment>

      {/* Sezione Aggiungi Nuova Voce */}
      <Segment padded>
        <Header as="h3">Aggiungi Nuova Voce</Header>
        <Form>
          <Form.Field>
            <Input
              fluid
              size="large"
              placeholder="Inserisci il testo da aggiungere"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </Form.Field>
          <Button primary fluid size="large" onClick={addTextEntry}>
            Aggiungi Voce di Testo
          </Button>
        </Form>
      </Segment>

      {/* Sezione Elimina Voce */}
      <Segment padded>
        <Header as="h3">Elimina Voce</Header>
        <Form>
          <Form.Field>
            <Input
              fluid
              size="large"
              placeholder="Inserisci l'ID della voce da eliminare"
              value={entryId}
              onChange={(e) => setEntryId(e.target.value)}
            />
          </Form.Field>
          <Button negative fluid size="large" onClick={deleteEntryById}>
            Elimina Voce
          </Button>
        </Form>
      </Segment>

      {/* Sezione Cerca Voci Simili */}
      <Segment padded>
        <Header as="h3">Cerca Voci Simili</Header>
        <Form>
          <Form.Field>
            <Input
              fluid
              size="large"
              placeholder="Inserisci la query di ricerca"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <Input
              fluid
              size="large"
              type="number"
              placeholder="Numero di risultati (k)"
              value={k}
              onChange={(e) => setK(e.target.value)}
            />
          </Form.Field>
          <Button secondary fluid size="large" onClick={searchEntries}>
            Cerca Voci
          </Button>
        </Form>
      </Segment>

      {/* Visualizza Risultati della Ricerca */}
      {searchResults && searchResults.length > 0 && (
        <Segment padded>
          <Header as="h4">Risultati della Ricerca</Header>
          <List divided relaxed>
            {searchResults.map((result, index) => (
              <List.Item key={index}>
                <List.Content>
                  <List.Header>Risultato {index + 1}</List.Header>
                  <List.Description>
                    <pre>{JSON.stringify(result, null, 2)}</pre>
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Segment>
      )}
    </Container>
  )
}

const mapStateToProps = (state) => ({
  login: state.login,
})

const mapDispatchToProps = (dispatch) => ({
  ...baseViewDispatch(dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(FaissManager)
