export function updateArticlePackage(data) {
    return new Promise((resolve, reject) => {
		let { updateArticlePackageRequest, updateArticlePackageError, updateArticlePackageResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'update_article_package',
			this.createBundle(
				'update_article_package',
				{ ...data },
				this.POST,
				updateArticlePackageRequest,
                (result) => {
                    updateArticlePackageResult(result)
                    resolve(result)
                },
                (error) => {
                    updateArticlePackageError(error)
                    reject(error)
                },
				true
			)
		);
	})
}
